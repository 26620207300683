import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

export default function Home() {
    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        const bodyFormData = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        //console.log({
        //    email: bodyFormData.get('username'),
        //    password: bodyFormData.get('password'),
        //    rememberme: event.currentTarget[4].checked,
        //});
        bodyFormData.set('rememberme', event.currentTarget[4].checked);
        getData(bodyFormData);
    };

    async function getData(bodyFormData) {
        const response = await axios({ url: 'login', method: 'post', data: bodyFormData, headers: { "Content-Type": "multipart/form-data" } });
        const data = await response.data;
        sessionStorage.setItem('user', JSON.stringify(data));
        var user = JSON.parse(sessionStorage.user);
        if (user.userRole.indexOf('Owner') >= 0) {
            history.push('/report/po');
        } else if (user.userRole != "") {
            history.push('/InvoiceData');
        } else {
            alert('Wrong login!');
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" id="rememberme" name="rememberme" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <br />
                    <div style={{ fontSize: 12, color: "darkblue"}}>ALC Micro &copy; copyright v2022.08.01</div>
                </Box>
            </Container>
        </ThemeProvider>
    );
}