import React from 'react';
import EnterOrder from '../Shared/EnterOrder';

export default function UploadBestBuy() {
    return (
        <>
            <EnterOrder URL={"csv?mtype=bestbuy&converted="} title={"Best Buy Orders"} isUploaded={true} sourceType={"bestbuy"}
                backgroundColor={"steelblue"} alternateColor={"lightblue"}  />
        </>
    );
}