import React from 'react';
import { dateFormat, twoDigitFormat } from '../Shared/Utils';
import DisplayGrid from '../Shared/DisplayGrid';

const columns = [
    { field: 'id', headerName: 'id', hide: true, headerClassName: 'steelheader' },
    { field: 'orderShipDate', headerName: 'OrderShipDate', width: 120, headerClassName: 'steelheader', type: "dateTime", valueFormatter: (params) => dateFormat(params) },
    { field: 'orderDate', headerName: 'OrderDate', width: 120, headerClassName: 'steelheader', type: "dateTime", valueFormatter: (params) => dateFormat(params) },
    { field: 'ponum', headerName: 'PONum', width: 100, headerClassName: 'steelheader' },
    { field: 'courierBy', headerName: 'CourierBy', flex: 70, headerClassName: 'steelheader' },
    { field: 'subTotal', headerName: 'SubTotal', flex: 70, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'tax1', headerName: 'Tax1', flex: 70, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'tax2', headerName: 'Tax2', flex: 70, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'total', headerName: 'Total', flex: 150, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) }
];

export default function POreport() {
    return (
        <DisplayGrid columns={columns} URL={"po"} title={"PO Report"} from={"2020-01-01"} dateFilter={true}
            id={(r) => r.id} custCodeFilter={false} defaultCustCode={""} cbCustCode={false} cbConverted={false} cbCancel={false}
            selectedRow={""} isUploaded={false} sourceType={""}
            backgroundColor={"#6E260E"} color={"white"} alternateColor={"#eecab1"} hoverColor={"lightgray"} pageSize={15} />
    );
}