import React from 'react';
import { dateFormat, twoDigitFormat } from '../Shared/Utils';
import DisplayGrid from '../Shared/DisplayGrid';

const columns = [
    { field: 'orderID', headerName: 'OrderID', width: 90, headerClassName: 'steelheader' },
    { field: 'poNum', headerName: 'PONum', width: 130, headerClassName: 'steelheader' },
    { field: 'employNo', headerName: 'Sales', width: 50, headerClassName: 'steelheader' },
    { field: 'orderDate', headerName: 'OrderDate', width: 110, headerClassName: 'steelheader', type: "dateTime", valueFormatter: (params) => dateFormat(params) },
    { field: 'subtotal', headerName: 'SubTotal', width: 80, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'tax1', headerName: 'Tax1', width: 80, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'insurance', headerName: 'Insurance', width: 70, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'shipCost', headerName: 'ShipCost', width: 70, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'total', headerName: 'Total', width: 90, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'totalCost', headerName: 'TotalCost', width: 90, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'totalProfit', headerName: 'TotalProfit', width: 90, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'orderStatus', headerName: 'OrderStatus', width: 70, headerClassName: 'steelheader' },
    { field: 'revision', headerName: 'Revision', width: 50, headerClassName: 'steelheader', type: 'number' },
    { field: 'orderShipDate', headerName: 'OrderShipDate', width: 120, headerClassName: 'steelheader', type: "dateTime", valueFormatter: (params) => dateFormat(params) },
    { field: 'itemCode', headerName: 'ItemCode', width: 150, headerClassName: 'steelheader' },
    { field: 'orderQty', headerName: 'OrderQty', width: 70, headerClassName: 'steelheader', type: 'number' },
    { field: 'price', headerName: 'Price', width: 70, headerClassName: 'steelheader', type: 'number' },
    { field: 'productCost', headerName: 'ProductCost ', width: 70, headerClassName: 'steelheader', type: 'number' },
    { field: 'custNo', headerName: 'CustCode', width: 70, headerClassName: 'steelheader' },
    { field: 'orderDetailID', headerName: 'OrderDetailID', hide:true }
];

export default function OrderReport() {
    return (
        <DisplayGrid columns={columns} URL={"report/OrderDetailReport/"} title={"Orders Report"} from={""} dateFilter={false}
            id={(r) => r.orderDetailID} custCodeFilter={true} defaultCustCode={"R0084"} custCodeLabel={"CustCode"} cbCustCode={false} cbConverted={false}
            selectedRow={""} isUploaded={false} sourceType={""}
            backgroundColor={"#6E260E"} color={"white"} alternateColor={"#eecab1"} hoverColor={"lightgray"} pageSize={15} />
   );
}