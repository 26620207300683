import React, { useEffect, useState } from 'react';
import DisplayGrid from '../Shared/DisplayGrid';
import { axiosURL } from '../Shared/Utils';
import { twoDigitFormatNum } from './Utils';
import axios from 'axios';

const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'itemCode', headerName: 'ItemCode', width: 170, headerClassName: 'steelheader' },
    { field: 'description', headerName: 'Description', width: 240, headerClassName: 'steelheader' },
    { field: 'note1', hide: true },
    { field: 'note2', hide: true },
    { field: 'note3', hide: true },
    { field: 'note4', hide: true },
    { field: 'marketPrice', hide: true },
    { field: 'bottomPrice', hide: true },
    { field: 'productCost', hide: true },
    { field: 'buyCost', hide: true },
    { field: 'onHand', hide: true },
    { field: 'isCommit', hide: true },
    { field: 'onOrder', hide: true }
];

function OrderDetailRow({ rowsData, deleteTableRows, handleChange }) {
    if (rowsData == undefined) { return null; }
    return (
        rowsData.map((data, index) => {
            var { itemCode, description, orderQty, price, productCost } = data;
            return (
                <>
                    <tr key={index}>
                        <td style={{ width: "19%" }}>{itemCode}</td>
                        <td style={{ width: "50%" }}><input type="text" value={description} name="description" onChange={e => handleChange(e, index)} style={{ width: "100%" }} /></td>
                        <td style={{ width: "8%" }}><input type="text" value={orderQty} name="orderQty" onChange={e => handleChange(e, index)} style={{ textAlign: 'right', width: "100%" }} /></td>
                        <td style={{ width: "10%" }}><input type="text" value={price} name="price" onChange={e => handleChange(e, index)} style={{ textAlign: 'right', width: "100%" }} /></td>
                        <td style={{ width: "5%", textAlign: 'right' }}> {productCost == 0 || productCost == undefined ? 0 : twoDigitFormatNum((price - productCost) * 100 / productCost)} </td>
                        <td style={{ width: "8%", textAlign: 'right' }}>{productCost ?? 0}</td>
                        <td><button onClick={() => (deleteTableRows(index))}>X</button></td>
                    </tr>
                </>
            )
        })
    );
}

export default function ItemCode(props) {
    const [selectedRow, setSelectedRow] = useState([]);
    const [sale, setSale] = useState([]);

    const childToParent = async (childdata) => {
        setSelectedRow(childdata);
        if (childdata[0] != undefined) {
            setSale(await axiosURL("sku/sale/" + childdata[0].itemCode + "/0"));
        }
    }
    const [myOrderData, setMyOrderData] = useState(props.details);

    useEffect(() => { calTotal(); }, [myOrderData.details]);

    const addTableRows = (e) => {
        var rowsInput = {};
        if (selectedRow[0] != undefined && e.target.value == "Add Item") {
            rowsInput = { itemCode: selectedRow[0].itemCode, description: selectedRow[0].description, orderQty: 1, price: selectedRow[0].marketPrice, productCost: selectedRow[0].productCost };
        }
        if (e.target.value == "Add Remark") {
            rowsInput = { itemCode: "REMARK", description: "", orderQty: 1, price: 0, productCost: 0 };
        } else if (e.target.value == "Add Note") {
            rowsInput = { itemCode: "NOTE", description: "", orderQty: 1, price: 0, productCost: 0 };
        }
        if (rowsInput != {}) {
            let mData = { ...myOrderData };
            mData.details = [...myOrderData.details, rowsInput];
            setMyOrderData(mData);
            props.itemCodeToOrderForm(myOrderData);
        }
    }

    const deleteTableRows = (index) => {
        const rows = [...myOrderData.details];
        rows.splice(index, 1);
        let mData = { ...myOrderData };
        mData.details = rows;
        setMyOrderData(mData);
        props.itemCodeToOrderForm(myOrderData);
    }

    const handleChange = (evnt, index) => {
        const { name, value } = evnt.target;
        let rowsInput = [...myOrderData.details];
        if (name == "description") {
            rowsInput[index].description = value;
        } else if (name == "orderQty") {
            rowsInput[index].orderQty = value;
        } else if (name == "price") {
            rowsInput[index].price = value;
        }
        let mData = { ...myOrderData };
        mData.details = rowsInput;
        setMyOrderData(mData);
        props.itemCodeToOrderForm(myOrderData);
    }

    function calTotal() {
        var tax1Rate = myOrderData.tax1 / (myOrderData.subTotal + myOrderData.shipping + myOrderData.insurance);
        var tax2Rate = myOrderData.tax2 / (myOrderData.subTotal + myOrderData.shipping + myOrderData.insurance);

        var totalCost = 0, subTotal = 0, totalProfit = 0;

        myOrderData.details.forEach(r => {
            subTotal += (r.price * r.orderQty);
            totalCost += (r.productCost * r.orderQty);
            if (!r.itemCode.startsWith("AS") && !r.itemCode.startsWith("MC") && r.itemCode != "EHF"
                && !r.itemCode.startsWith("FREIG")) {
                totalProfit += ((r.price - r.productCost) * r.orderQty);
            }
        });

        let mData = { ...myOrderData };
        mData.subTotal = subTotal;
        mData.totalCost = totalCost;
        mData.tax1 = (mData.subTotal + mData.shipping + mData.insurance) * tax1Rate;
        mData.tax2 = (mData.subTotal + mData.shipping + mData.insurance) * tax2Rate;
        mData.totalProfit = totalProfit;
        mData.total = mData.subTotal + mData.tax1 + mData.tax2 + mData.insurance ?? 0 + mData.shipping ?? 0;

        setMyOrderData(mData);
        props.itemCodeToOrderForm(myOrderData);
    }

    return (
        <> <table className="styled-table">
            <thead>
                <tr><th> &nbsp;Item No</th><th> &nbsp;Item Description</th><th> &nbsp;Qty</th><th> &nbsp;Price</th><th> &nbsp;Markup%</th><th> &nbsp;Cost</th><th> &nbsp;Del</th></tr>
            </thead>
            <tbody><OrderDetailRow rowsData={myOrderData.details} deleteTableRows={deleteTableRows} handleChange={handleChange} /></tbody>
        </table>
            <br />
            <table>
                <tr><td style={{ verticalAlign: "top", width: "80%" }}>
                    <table>
                        <tr style={{ verticalAlign: "top" }}>
                            <td>
                                <DisplayGrid columns={columns} URL={"sku/search/"} title={"Products"} from={""} dateFilter={false}
                                    id={(r) => r.id} custCodeFilter={true} defaultCustCode={"RNB"} custCodeLabel={"Search"} cbCustCode={false}
                                    selectedRow={childToParent} isUploaded={false} sourceType={""}
                                    backgroundColor={"#6E260E"} color={"white"} alternateColor={"#eecab1"} hoverColor={"lightgray"} pageSize={5} />
                                <input type="button" onClick={addTableRows} value="Add Item" /> &nbsp;&nbsp;&nbsp;
                                <input type="button" onClick={addTableRows} value="Add Note" /> &nbsp;&nbsp;&nbsp;
                                <input type="button" onClick={addTableRows} value="Add Remark" />
                            </td>
                            <td style={{ width: "5px" }}></td>
                            <td>
                                {selectedRow[0] != undefined &&
                                    <><table className="styled-table">
                                        <tr><th>Customer Price</th><td style={{ textAlign: 'right' }}>{selectedRow[0].marketPrice}</td>
                                            <th>On Hand</th><td style={{ textAlign: 'right' }}>{selectedRow[0].onHand}</td></tr>
                                        <tr><th>Minimum Price</th><td style={{ textAlign: 'right' }}>{selectedRow[0].bottomPrice}</td>
                                            <th>Commit</th><td style={{ textAlign: 'right' }}>{selectedRow[0].isCommit}</td></tr>
                                        <tr><th>Product Cost</th><td style={{ textAlign: 'right' }}>{selectedRow[0].productCost}</td>
                                            <th>On Order</th><td style={{ textAlign: 'right' }}>{selectedRow[0].onOrder}</td></tr>
                                    </table>
                                        <br />
                                        <table className="styled-table">
                                            <tr><th>Note1</th><td>{selectedRow[0].note1}</td></tr>
                                            <tr><th>Note2</th><td>{selectedRow[0].note2}</td></tr>
                                            <tr><th>Note3</th><td>{selectedRow[0].note3}</td></tr>
                                            <tr><th>Note4</th><td>{selectedRow[0].note4}</td></tr>
                                        </table>
                                        <br />
                                        {sale != undefined &&
                                            <table className="styled-table">
                                                <tr><th>PostSale1</th><td style={{ textAlign: 'right' }}>{sale.postsale1}</td>
                                                    <th>Cost1</th><td style={{ textAlign: 'right' }}>{sale.cost1}</td></tr>
                                                <tr><th>PostSale2</th><td style={{ textAlign: 'right' }}>{sale.postsale2}</td>
                                                    <th>Cost2</th><td style={{ textAlign: 'right' }}>{sale.cost2}</td></tr>
                                                <tr><th>PostSale3</th><td style={{ textAlign: 'right' }}>{sale.postsale3}</td>
                                                    <th>Cost3</th><td style={{ textAlign: 'right' }}>{sale.cost3}</td></tr>
                                            </table>
                                        }
                                    </>
                                }
                            </td>
                        </tr>
                    </table>
                </td>
                    <td style={{ width: "10px" }}></td>
                    <td style={{ verticalAlign: "bottom", minwidth: "200px" }}>
                        <table id="total">
                            <tbody>
                                <tr><th>Total Cost:</th><td style={{ textAlign: 'right', width: "50px" }}>{twoDigitFormatNum(myOrderData.totalCost)} </td></tr>
                                <tr><th>Gross Profit:</th><td style={{ textAlign: 'right', width: "50px" }}>{twoDigitFormatNum(myOrderData.totalProfit)}</td></tr>
                                <tr><th>Subtotal:</th><td style={{ textAlign: 'right', width: "50px" }}>{twoDigitFormatNum(myOrderData.subTotal)}</td></tr>
                                <tr><th>Shipping:</th><td style={{ textAlign: 'right', width: "50px" }}>{twoDigitFormatNum(myOrderData.shipping)}</td></tr>
                                <tr><th>Insurance:</th><td style={{ textAlign: 'right', width: "50px" }}>{twoDigitFormatNum(myOrderData.insurance)}</td></tr>
                                <tr><th>GST/HST:</th><td style={{ textAlign: 'right', width: "50px" }}>{twoDigitFormatNum(myOrderData.tax1)}</td></tr>
                                <tr><th>PST:</th><td style={{ textAlign: 'right', width: "50px" }}>{twoDigitFormatNum(myOrderData.tax2)}</td></tr>
                                <tr><td colSpan="2">------------------------------</td></tr>
                                <tr><th>Total:</th><td style={{ textAlign: 'right', width: "100px" }}>{twoDigitFormatNum(myOrderData.total)}</td></tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </table>
        </>
    );
}