import React from 'react';
import EnterOrder from '../Shared/EnterOrder';

export default function UploadAmazon() {
    return (
        <>
            <EnterOrder URL={"csv?mtype=amazon&converted="} title={"Amazon Orders"} isUploaded={true} sourceType={"amazon"}
                backgroundColor={"steelblue"} alternateColor={"lightblue"}   />
        </>
    );
}