import React, { useState } from 'react';
import { dateFormat, twoDigitFormat } from './Utils';
import DisplayGrid from './DisplayGrid';
import OrderForm from './OrderForm';

const columns = [
    { field: 'id', headerName: 'id', hide: true, headerClassName: 'steelheader' },
    { field: 'cOrderID', headerName: 'ordererid', hide: true, headerClassName: 'steelheader' },
    { field: 'orderDate', headerName: 'Order Date', width: 140, headerClassName: 'steelheader', type: "dateTime", valueFormatter: (params) => dateFormat(params) },
    { field: 'ponum', headerName: 'PO Number', width: 180, headerClassName: 'steelheader', type: "string" },
    { field: 'orderStatus', headerName: 'Status', width: 180, headerClassName: 'steelheader', type: "string" },
    { field: 'contact', headerName: 'Contact', width: 180, headerClassName: 'steelheader', type: "string" },
    { field: 'total', headerName: 'Total', width: 100, headerClassName: 'steelheader', type: "number" },
    { field: 'tax1', headerName: 'Tax', width: 80, headerClassName: 'steelheader', type: "number" },
    { field: 'phone', headerName: 'Phone', width: 120, headerClassName: 'steelheader', type: "string" },
    { field: 'email', headerName: 'Email', width: 320, headerClassName: 'steelheader', type: "string" },
    { field: 'uploadedDate', headerName: 'Uploaded Date', width: 140, headerClassName: 'steelheader', type: "dateTime", valueFormatter: (params) => dateFormat(params) },
    { field: 'convertedDate', headerName: 'Converted Date', width: 150, headerClassName: 'steelheader', type: "dateTime", valueFormatter: (params) => dateFormat(params) }
];

export default function EnterOrder(props) {
    const [selectedRow, setSelectedRow] = useState([]);

    const childToParent = (childdata) => {
        setSelectedRow(childdata);
    }

    return (
        <>
            <DisplayGrid columns={columns} URL={props.URL} title={props.title} from={""} dateFilter={false}
                id={(r) => r.id} custCodeFilter={false} defaultCustCode={""} cbCustCode={false} cbConverted={false} cbCancel={false}
                selectedRow={childToParent} isUploaded={props.isUploaded} sourceType={props.sourceType}
                backgroundColor={props.backgroundColor} alternateColor={props.alternateColor} color={"white"}  hoverColor={"lightgray"} pageSize={5} />

            {selectedRow.length > 0 && selectedRow[0].cOrderID == 0 &&
                <OrderForm selectedRow={selectedRow[0]} sourceType={props.sourceType } />
            }

            {selectedRow.length > 0 && selectedRow[0].cOrderID > 0 &&
                <p>This Data has already been converted to Order # {selectedRow[0].cOrderID}</p>
            }
        </>
    );
}