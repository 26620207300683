import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { makeStyles } from "@mui/styles";
import { Checkbox } from '@mui/material';
import $ from 'jquery';
import UploadFile from '../Shared/UploadFile';
import { axiosURL } from '../Shared/Utils';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

//function name and X.jsx must start with capital letters
export default function DisplayGrid(props) {
    const columns = props.columns;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasCustcode, sethasCustcode] = useState(true);
    const [hasConverted, sethasConverted] = useState(false);
    const [hasCancel, sethasCancel] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [converted, setConverted] = useState(true);
    const [uploaded, setUploaded] = useState(false);

    //set default to Today date for To 
    var curr = new Date();
    var date = curr.toISOString().substr(0, 10);

    const useStyles = makeStyles({
        root: {
            '& .steelheader': {
                backgroundColor: props.backgroundColor, color: props.color
            },
            "& .MuiDataGrid-renderingZone": {
                "& .MuiDataGrid-row": {
                    "&:nth-child(2n)": {
                        backgroundColor: props.alternateColor
                    },
                    "&:hover": {
                        backgroundColor: props.hoverColor
                    },
                }
            },
            "& .Mui-selected": {
                backgroundColor: "yellow !important"
            },
        },
    });
    const classes = useStyles();

    useEffect(async () => {
        getData();
    }, props.isUploaded ? [converted, uploaded] : props.cbCustCode ? [hasCustcode] : (props.cbConverted || props.cbCancel) ? [hasConverted, hasCancel] : [props.URL]);

    async function getData() {
        setLoading(true);
        var queryURL = props.URL;
        if (props.dateFilter) {
            var fromDate = new Date($('#from').val());
            var toDate = new Date($('#to').val());
            if (toDate > fromDate) {
                fromDate = fromDate.toISOString().split('T')[0];
                toDate = toDate.toISOString().split('T')[0];
                queryURL += '?fromdate=' + fromDate + '&todate=' + toDate;
            }
        } else if (props.custCodeFilter) {
            var custno = $('#custno').val();
            queryURL += custno;
        } else if (props.cbCustCode) {
            queryURL += hasCustcode + "&custsearch=" + $('#custSearch').val();
        } else if (props.isUploaded) {
            queryURL += converted;
        }

        if (props.cbConverted) {
            queryURL += "&hasConverted=" + hasConverted;
        }

        if (props.cbCancel) {
            queryURL += "&hasCancel=" + hasCancel;
        }

        setData(await axiosURL(queryURL));
        setLoading(false);
    }

    function handleEnter(e) {
        if (e.keyCode == 13) {
            $('#go').click();
        }
    }

    function handleConvertedOrderChange(event) {
        sethasConverted(event.target.checked);
        if (event.target.checked) {
            $('#cbCancel').prop('checked', false);
            sethasCancel(false);
        }
    }

    function handleCancelChange(event) {
        sethasCancel(event.target.checked);
        if (event.target.checked) {
            $('#cbConverted').prop('checked', false);
            sethasConverted(false);
        }
    }

    function handleCustCodeChange(event) {
        sethasCustcode(event.target.checked);
    }

    function handleConvertedChange(event) {
        setConverted(event.target.checked);
    }

    return (
        <div style={{ height: 360, width: '100%' }} className={classes.root}>
            <span className='title'>{props.title} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            {props.dateFilter == true &&
                <span>From: <input type="date" id="from" name="from" defaultValue={props.from} />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To: <input type="date" id="to" name="to" defaultValue={date} />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="button" onClick={getData} value="Go" />
               </span>
            }

            {props.custCodeFilter == true &&
                <span> {props.custCodeLabel}: <input type="text" id="custno" name="custno" defaultValue={props.defaultCustCode} onKeyDown={handleEnter} style={{width:'100px'}} />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="button" id="go" onClick={getData} value="Go" />
                </span>
            }

            {props.cbCustCode == true &&
                <>
                Search: <input type="text" id="custSearch" style={{ width: "400px" }} placeholder="CustCode / Company / Phone / Contact" />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Checkbox defaultChecked color="success" onChange={handleCustCodeChange} />Has CustCode
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="button" id="go" onClick={getData} value="Go" />
                </>
            }

            {props.cbConverted == true &&
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" id="cbConverted" color="success" onChange={handleConvertedOrderChange} /> Converted</span>
            }

            {props.cbCancel == true &&
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" id="cbCancel" color="success" onChange={handleCancelChange} /> Cancelled</span>
            }

            {props.isUploaded == true &&
                <span><Checkbox defaultChecked color="success" onChange={handleConvertedChange} />Not Converted
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <UploadFile sourceType={props.sourceType} setUploaded={setUploaded} />
                </span>
            }

            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid autoHeight components={{ Toolbar: CustomToolbar }}
                        getRowId={props.id}
                        rows={data}
                        columns={columns}
                        pageSize={props.pageSize}
                        rowHeight={30}
                        disableMultipleSelection={ true }
                        onSelectionModelChange={(newSelectionModel) => {
                            const selectedIDs = new Set(newSelectionModel);
                            var selectedRow;
                            if (props.cbCustCode) {
                                selectedRow = data.filter((r) => (selectedIDs.has(r.custrefno)));
                            } else {
                                selectedRow = data.filter((r) => (selectedIDs.has(r.id)));
                            }

                            { props.selectedRow != "" && props.selectedRow(selectedRow); }
                            setSelectedRow(selectedRow);
                        }}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}