import axios from 'axios';

export function dateFormat(params) {
    if (params.value == undefined) { return "";}
    const date = new Date(params.value);
    return date.toDateString().slice(4);
}

export function dateTimeFormat(params) {
    if (params.value == undefined) { return ""; }
    const date = new Date(params.value);
    return date.toDateString().slice(4) + " " + date.toTimeString().substring(0, 5);
}

export function dateFormatNoT(params) {
    return params?.replace('T',' ').substring(0,16);
}

export function twoDigitFormatNum(params) {
    if (params) {
        return params.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else {
        return (0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
}

export function twoDigitFormat(params) {
    if (params.value) {
        return (params.value).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2});
    } else {
        return (0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
}

export async function axiosURL(queryURL) {
    try {
        const response = await axios(queryURL);
        if (response.status == 200) {
            const data = await response.data;
            return data
        } else {
            alert(response.data);
        }
    } catch (err) {
        alert(err);
    }
    return [];
}