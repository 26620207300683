import React, { useState } from 'react';
import { dateTimeFormat, twoDigitFormat } from '../Shared/Utils';
import DisplayGrid from '../Shared/DisplayGrid';
import DisplayForm from './DisplayForm';

const columns = [
    { field: 'id', headerName: 'ID', width: 100, headerClassName: 'steelheader' },
    { field: 'invDate', headerName: 'Order Date', width: 160, headerClassName: 'steelheader', type: "dateTime", valueFormatter: (params) => dateTimeFormat(params) },
    { field: 'custCode', headerName: 'CustCode', width: 100, headerClassName: 'steelheader' },
    { field: 'company', headerName: 'Company', width: 280, headerClassName: 'steelheader' },
    { field: 'poNum', headerName: 'PO Number', width: 200, headerClassName: 'steelheader' },
    { field: 'courierBy', headerName: 'CourierBy', width: 150, headerClassName: 'steelheader' },
    { field: 'total', headerName: 'Total', width: 120, headerClassName: 'steelheader', type: "number", valueFormatter: (params) => twoDigitFormat(params) }
];

export default function BackorderData() {
    const [selectedRow, setSelectedRow] = useState([]);

    const childToParent = (childdata) => {
        setSelectedRow(childdata);
    }

    //set default to Today date for To 
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    var yesDate = yesterday.toISOString().substr(0, 10);

    return (
        <>
            <DisplayGrid columns={columns} URL={"backorder"} title={"Back Order"} from={yesDate} dateFilter={true}
                id={(r) => r.id} custCodeFilter={false} defaultCustCode={""} cbCustCode={false} cbConverted={false} cbCancel={false}
                selectedRow={childToParent} isUploaded={false} sourceType={""}
                backgroundColor={"steelblue"} color={"white"} alternateColor={"lightblue"} hoverColor={"lightgray"} pageSize={5} />

            {selectedRow.length > 0 &&
                <DisplayForm selectedRow={selectedRow[0]} mType={"backorder" }  />
            }
        </>
    );
}