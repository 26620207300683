import React, { useEffect, useState } from 'react';
import { dateFormatNoT, twoDigitFormatNum, twoDigitFormat } from '../Shared/Utils';
import DisplayGrid from '../Shared/DisplayGrid';

export default function CustomerForm(props) {

    const [myCustData, setMyCustData] = useState(props.selectedRow);

    if (props.selectedRow != undefined && props.selectedRow != "" && props.selectedRow.id != myCustData.id) {
        setMyCustData(props.selectedRow);
    };

    return (
        <>
            {props.selectedRow != undefined && props.selectedRow != "" &&
                <div className="display-box">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: "9%" }}>Company</td>
                                <td style={{ width: "35%" }}><input type="text" value={myCustData.company} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "14%" }}>&nbsp; CustCode</td>
                            <td style={{ width: "14%" }}><input type="text" value={myCustData.custno} readonly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "14%" }}>&nbsp; Dept Code</td>
                            <td style={{ width: "14%" }}><input type="text" value="" readonly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td>Contact</td>
                                <td><input type="text" value={myCustData.contact} style={{ width: "100%" }} /></td>
                                <td>&nbsp; PST No</td>
                                <td><input type="text" value={myCustData.tpstno} style={{ width: "100%" }} /></td>
                                <td>&nbsp; Price Lvl(0-10)</td>
                            <td><input type="text" value="" readonly style={{ backgroundColor: 'lightgray', width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td>Address1</td>
                            <td><input type="text" value={myCustData.address1} style={{ width: "100%" }} /></td>
                            <td>&nbsp; Currency</td>
                            <td><input type="text" value={myCustData.tcurrency} style={{ width: "100%" }} /></td>
                            <td>&nbsp; GST</td>
                            <td><input type="text" value={myCustData.tftax} style={{ textAlign: 'right', width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td>Address2</td>
                            <td><input type="text" value={myCustData.address2} style={{ width: "100%" }} /></td>
                            <td>&nbsp; Credit limit</td>
                            <td><input type="text" value={myCustData.creditltd} style={{ textAlign: 'right', width: "100%" }} /></td>
                            <td>&nbsp; PST</td>
                            <td><input type="text" value={myCustData.tptax} style={{ textAlign: 'right', width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td><input type="text" value={myCustData.city} style={{ width: "100%" }} /></td>
                            <td>&nbsp; Due Amount</td>
                            <td><input type="text" value={myCustData.dueAmt} readonly style={{ backgroundColor: 'lightgray', textAlign: 'right', width: "100%" }} /></td>
                            <td>&nbsp; Business Type</td>
                            <td><input type="text" value="" readonly style={{ backgroundColor: 'lightgray', width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td>PostalCode</td>
                            <td><input type="text" value={myCustData.postcode} style={{ width: "100%" }} /></td>
                            <td>&nbsp; On Order Amt</td>
                            <td><input type="text" value={myCustData.onOrderAmt} readonly style={{ backgroundColor: 'lightgray', textAlign: 'right', width: "100%" }} /></td>
                            <td>&nbsp; Free Insurance</td>
                            <td><select id="freeIns" name="freeIns" value={ myCustData.freeIns}><option value="true">Yes</option><option value="false">No</option></select> </td>
                        </tr>
                        <tr>
                            <td>Province</td>
                            <td><input type="text" value={myCustData.province} style={{ width: "100%" }} /></td>
                            <td>&nbsp; Net Days <input type="text" value={myCustData.netday} style={{ width: "25%" }} /></td>
                            <td><select id="payCondit" name="payCondit" value={myCustData.payCondit}>
                                <option value={myCustData.payCondit}>{myCustData.payCondit}</option>
                                <option value="C.O.D.">C.O.D.</option>
                                <option value="P.D.Chq">P.D.Chq</option>
                                <option value="Credit Card">Credit Card</option>
                                <option value="CASH">CASH</option>
                                <option value="Cert. chq">Cert. chq</option>
                           </select> </td>
                            <td>&nbsp; Start Inv Date</td>
                            <td><input type="text" value={dateFormatNoT(myCustData.startInvDate)} readonly style={{ backgroundColor: 'lightgray', width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td>Country</td>
                            <td><input type="text" value={myCustData.country} style={{ width: "100%" }} /></td>
                            <td>&nbsp; Order Stop by</td>
                            <td><input type="text" value={myCustData.orderStopBy} readonly style={{ backgroundColor: 'lightgray', width: "100%" }} /></td>
                            <td>&nbsp; Last Inv Date</td>
                            <td><input type="text" value={dateFormatNoT(myCustData.lastInvDate)} readonly style={{ backgroundColor: 'lightgray', width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td>Fax</td>
                            <td><input type="text" value={myCustData.fax} style={{ width: "100%" }} /></td>
                            <td>&nbsp; Overdue Amt</td>
                            <td><input type="text" value={myCustData.overdueAmt} readonly style={{ backgroundColor: 'lightgray',textAlign: 'right', width: "100%" }} /></td>
                            <td>&nbsp; Ave Pay Day</td>
                            <td><input type="text" value={myCustData.avePayDay} readonly style={{ backgroundColor: 'lightgray',textAlign: 'right',width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td><input type="text" value={myCustData.phone} style={{ width: "100%" }} /></td>
                            <td>&nbsp; OverDueDay</td>
                            <td><input type="text" value={myCustData.overdueDay} readonly style={{ backgroundColor: 'lightgray', textAlign: 'right', width: "100%" }} /></td>
                            <td>&nbsp; Last Pay Amt</td>
                            <td><input type="text" value={myCustData.lastPayAmt} readonly style={{ backgroundColor: 'lightgray',textAlign: 'right', width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td><input type="text" value={myCustData.email} style={{ width: "100%" }} /></td>
                            <td>&nbsp; Forecst MTD</td>
                            <td><input type="text" value="" readonly style={{ backgroundColor: 'lightgray', width: "100%" }} /></td>
                            <td>&nbsp; Last Pay Date</td>
                            <td><input type="text" value={dateFormatNoT(myCustData.lastPayDate)} readonly style={{ backgroundColor: 'lightgray', width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td>Saleman </td>
                            <td><input type="text" value={myCustData.employno} style={{ width: "35%" }} />
                                &nbsp;&nbsp;&nbsp; Approve <input type="text" value={myCustData.approveby} readonly style={{ backgroundColor: 'lightgray', width: "35%" }} /></td>
                            <td>&nbsp; Sales MTD</td>
                            <td><input type="text" value={myCustData.saleMtd} readonly style={{ backgroundColor: 'lightgray',textAlign: 'right', width: "100%" }} /></td>
                            <td>&nbsp; Sales YTD</td>
                            <td><input type="text" value={myCustData.saleYtd} readonly style={{ backgroundColor: 'lightgray',textAlign: 'right', width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td>Employno</td>
                            <td><input type="text" value={myCustData.employno} style={{ width: "35%" }} /></td>
                            <td>&nbsp; Profit MTD</td>
                            <td><input type="text" value={myCustData.profitMtd} readonly style={{ backgroundColor: 'lightgray', textAlign: 'right',width: "100%" }} /></td>
                            <td>&nbsp; Profit YTD</td>
                            <td><input type="text" value={myCustData.profitYtd} readonly style={{ backgroundColor: 'lightgray', textAlign: 'right', width: "100%" }} /></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>&nbsp; Paid MTD</td>
                            <td><input type="text" value={myCustData.paidMtd} readonly style={{ backgroundColor: 'lightgray', textAlign: 'right',width: "100%" }} /></td>
                            <td>&nbsp; Paid YTD</td>
                            <td><input type="text" value={myCustData.paidYtd} readonly style={{ backgroundColor: 'lightgray', textAlign: 'right', width: "100%" }} /></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
}