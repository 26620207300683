import React, { useEffect, useState } from 'react';
import { dateFormatNoT, twoDigitFormatNum, twoDigitFormat } from '../Shared/Utils';
import DisplayGrid from '../Shared/DisplayGrid';

const columns = [
    { field: 'id', headerName: 'id', hide: true, headerClassName: 'steelheader' },
    { field: 'itemCode', headerName: 'ItemCode', width: 200, headerClassName: 'steelheader' },
    { field: 'description', headerName: 'Description', width: 200, headerClassName: 'steelheader' },
    { field: 'orderQty', headerName: 'orderQty', width: 100, headerClassName: 'steelheader', type: 'number' },
    { field: 'shipQty', headerName: 'shipQty', width: 100, headerClassName: 'steelheader', type: 'number' },
    { field: 'price', headerName: 'Price', flex: 150, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'productCost', headerName: 'Cost', flex: 150, headerClassName: 'steelheader', type: 'number', valueFormatter: (params) => twoDigitFormat(params) },
    { field: 'status', headerName: 'Status', flex: 100, headerClassName: 'steelheader' }
];

export default function DisplayForm(props) {

    const [myOrderData, setMyOrderData] = useState(props.selectedRow);

    if (props.selectedRow != undefined && props.selectedRow != "" && props.selectedRow.id != myOrderData.id) {
        setMyOrderData(props.selectedRow);
    };

    return (
        <>
            {props.selectedRow != undefined && props.selectedRow != "" &&
                <div className="display-box">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: "10%" }}>Date</td>
                            <td style={{ width: "30%" }}><input type="text" value={dateFormatNoT(myOrderData.invDate)} readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Sub Total</td>
                                <td style={{ width: "10%" }}><input type="text" value={twoDigitFormatNum(myOrderData.subTotal)} readOnly style={{ textAlign: 'right', backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Emp # </td>
                                <td style={{ width: "10%" }}><input type="text" value={myOrderData.sales} readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "20%" }}>&nbsp; Shipping Company</td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}>Company</td>
                                <td style={{ width: "30%" }}><input type="text" value={myOrderData.company} readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Insurance</td>
                                <td style={{ width: "10%" }}><input type="text" value={twoDigitFormatNum(myOrderData.insurance)} readOnly style={{ textAlign: 'right', backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Pick By</td>
                                <td style={{ width: "10%" }}><input type="text" value={myOrderData.pickBy} readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "20%", paddingLeft: "5px" }}><input type="text" value={myOrderData.shippingcompany} readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}>Cust Code</td>
                                <td style={{ width: "30%" }}><input type="text" value={myOrderData.custCode} readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Shipping</td>
                                <td style={{ width: "10%" }}><input type="text" value={twoDigitFormatNum(myOrderData.shipping)} readOnly style={{ textAlign: 'right', backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Scan By</td>
                                <td style={{ width: "10%" }}><input type="text" value={myOrderData.scanBy} readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "20%" }}>&nbsp; Tracking #</td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}>Total Cost</td>
                                <td style={{ width: "30%" }}><input type="text" value={twoDigitFormatNum(myOrderData.totalCost)} readOnly style={{ textAlign: 'right', backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; PST</td>
                                <td style={{ width: "10%" }}><input type="text" value={twoDigitFormatNum(myOrderData.pst)} readOnly style={{ textAlign: 'right', backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Ship By</td>
                                <td style={{ width: "10%" }}><input type="text" value={myOrderData.shipBy} readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "20%", paddingLeft: "5px" }} colSpan="4" rowSpan="3"><textarea value={myOrderData.trackingNum} rows="3" style={{ backgroundColor: 'lightgray', width: "100%" }} readOnly> </textarea></td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}>Total Profit</td>
                                <td style={{ width: "30%" }}><input type="text" value={twoDigitFormatNum(myOrderData.totalProfit)} readOnly style={{ textAlign: 'right', backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; GST / HST</td>
                                <td style={{ width: "10%" }}><input type="text" value={twoDigitFormatNum(myOrderData.gsthst)} readOnly style={{ textAlign: 'right', backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Currency</td>
                                <td style={{ width: "10%" }}><input type="text" value={myOrderData.currency} readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}>ShipCost</td>
                                <td style={{ width: "30%" }}><input type="text" value={twoDigitFormatNum(myOrderData.shipCost)} readOnly style={{ textAlign: 'right', backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Total</td>
                                <td style={{ width: "10%" }}><input type="text" value={twoDigitFormatNum(myOrderData.total)} readOnly style={{ textAlign: 'right', backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Order By</td>
                                <td style={{ width: "10%" }}><input type="text" value={myOrderData.orderBy} readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                            </tr>
                        </tbody>
                </table><br />
                <DisplayGrid columns={columns} URL={"saledetail?headerid=" + myOrderData.id + "&mtype=" + props.mType} title={""} from={""} dateFilter={false}
                    id={(r) => r.id} custCodeFilter={false} defaultCustCode={""} cbCustCode={false} cbConverted={false} cbCancel={false}
                    selectedRow={""} isUploaded={false} sourceType={""}
                    backgroundColor={"steelblue"} color={"white"} alternateColor={"lightblue"} hoverColor={"lightgray"} pageSize={10} />
                </div>
            }
        </>
    );
}