import React, { Component } from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown,
    DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import './NavMenu.css';
import axios from 'axios';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    logout() {
        sessionStorage.clear();
        const response = axios({ url: 'login', method: 'get' });
    }

    render() {

        const u = sessionStorage.getItem("user");
        var user = JSON.parse(u);
        let isOwner = (user != null && user.userRole.includes("Owner") ? true : false);
        let isWarehouse = (isOwner || (user != null && user.userRole.includes("WH")) ? true : false);
        let isSales = (isOwner || (user != null && user.userRole.includes("Sales")) ? true : false);

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 navbar-header" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">ALC BOS</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {isSales &&
                                    <UncontrolledDropdown nav in Navbar>
                                        <DropdownToggle nav caret className="text-dark">
                                            Sales
                                        </DropdownToggle>
                                        <DropdownMenu right className="myLightGray">
                                            <DropdownItem tag={Link} to="/customerdata" activeClassName="title">
                                                Customer
                                            </DropdownItem>
                                        <DropdownItem tag={Link} to="/quotationdata" activeClassName="title">
                                                Quotations
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/orderdata" activeClassName="title">
                                                Orders
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/invoicedata" activeClassName="title">
                                                Invoices
                                            </DropdownItem>
                                        <DropdownItem tag={Link} to="/backorderdata" activeClassName="title">
                                                Back Orders
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/uploadamazon" activeClassName="title">
                                                Amazon Upload CSV
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/uploadbestbuy" activeClassName="title">
                                                Best Buy Upload CSV
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                }
                                {/*{isWarehouse &&*/}
                                {/*    <UncontrolledDropdown nav in Navbar>*/}
                                {/*        <DropdownToggle nav caret className="text-dark">*/}
                                {/*            Warehouse*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu right className="myLightGray">*/}
                                {/*            <DropdownItem tag={Link} to="/report/po" activeClassName="title">*/}
                                {/*                Orders Scan*/}
                                {/*            </DropdownItem>*/}
                                {/*            <DropdownItem tag={Link} to="/report/order" activeClassName="title">*/}
                                {/*                Invoices Shipping*/}
                                {/*            </DropdownItem>*/}
                                {/*        </DropdownMenu>*/}
                                {/*    </UncontrolledDropdown>*/}
                                {/*}*/}
                                {isOwner &&
                                    <UncontrolledDropdown nav in Navbar>
                                        <DropdownToggle nav caret className="text-dark">
                                            Report
                                        </DropdownToggle>
                                        <DropdownMenu right className="myLightGray">
                                            <DropdownItem tag={Link} to="/report/po" activeClassName="title">
                                                PO Report
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/report/order" activeClassName="title">
                                                Orders Report
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                }
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/" onClick={() => this.logout()}>Logout</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
