import React, { Component } from 'react';
import './custom.css'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Layout } from './components/Layout';
import Home from './components/Home';
import CustomerData from './components/Sales/CustomerData';
import InvoiceData from './components/Sales/InvoiceData';
import OrderData from './components/Sales/OrderData';
import BackorderData from './components/Sales/BackorderData';
import QuotationData from './components/Sales/QuotationData';
import POreport from './components/Report/POreport';
import OrderReport from './components/Report/OrderReport';
import UploadBestbuy from './components/Sales/UploadBestbuy';
import UploadAmazon from './components/Sales/UploadAmazon';

export default function App() {

    const u = sessionStorage.getItem("user");
    var user = JSON.parse(u);

    let isLogin = (user === null || user.userRole === "" ? false : true);

    let isOwner = (user != null && user.userRole.includes("Owner") ? true : false);

    const location = useLocation();

    return (
        <div>
            <Switch>
                {!isLogin && location.pathname != '/' ? (
                    <Redirect to='/' />
                ) : !isLogin ? (
                    <Route exact path='/' component={Home} />
                ) : isOwner ? (
                    <Layout>
                        <Route exact path='/' component={Home} />
                        <Route path='/customerdata' component={CustomerData} />
                        <Route path='/orderdata' component={OrderData} />
                        <Route path='/quotationdata' component={QuotationData} />
                        <Route path='/backorderdata' component={BackorderData} />
                        <Route path='/invoicedata' component={InvoiceData} />
                        <Route path='/uploadamazon' component={UploadAmazon} />
                        <Route path='/uploadbestbuy' component={UploadBestbuy} />
                        <Route path='/report/po' component={POreport} />
                        <Route path='/report/order' component={OrderReport} />
                    </Layout>
                ) : (
                    <Layout>
                        <Route exact path='/' component={Home} />
                        <Route path='/customerdata' component={CustomerData} />
                        <Route path='/invoicedata' component={InvoiceData} />
                    </Layout>
                )}
            </Switch>
        </div>
        //</Layout>
    );
}
