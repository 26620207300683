import React, { useEffect, useState } from 'react';
import { axiosURL, dateFormatNoT, twoDigitFormatNum } from './Utils';
import ItemCode from './ItemCode';
import axios from 'axios';
import { Autocomplete, TextField } from '@mui/material';
import $ from 'jquery';

export default function OrderForm(props) {

    var x = document.getElementById("total");

    const [myOrderData, setMyOrderData] = useState(props.selectedRow);
    const [empData, setEmpData] = useState([]);
    const [custCode, setCustCode] = useState([]);

    const itemCodeToOrderForm = async (childdata) => {
        let mData = { ...myOrderData };
        mData.details = childdata.details;
        mData.total = childdata.total;
        mData.totalCost = childdata.totalCost;
        mData.totalProfit = childdata.totalProfit;
        mData.subTotal = childdata.subTotal;
        mData.shipping = childdata.shipping;
        mData.insurance = childdata.insurance;
        mData.tax1 = childdata.tax1;
        mData.tax2 = childdata.tax2;
        setMyOrderData(mData);
    }

    async function saveOrder() {
        myOrderData.employNo = $('#mEmpData').val();
        myOrderData.custCode = $('#mCustCode').val();
        const response = await axios({ url: 'order', method: 'post', data: myOrderData });
        const res = await response.data;
        alert(res);
    }

    if (props.selectedRow != undefined && props.selectedRow != "" && props.selectedRow.id != myOrderData.id) {
        setMyOrderData(props.selectedRow);
    }

    useEffect(async () => {
        setEmpData(await axiosURL("employee/getLogin"));
        setCustCode(await axiosURL("customer/getCustCode?containCompany=" + props.sourceType + "&containCustCode="));
    }, []);

    function calculate() {
        var freeship = document.getElementById("freeShip");
        if (freeship != null && freeship.value == "No") { myOrderData.shipping = myOrderData.shippingAmt; myOrderData.freeShipping = false; }
        else { myOrderData.shipping = 0; myOrderData.freeShipping = true; }
        var freeins = document.getElementById("freeIns");
        var ins = document.getElementById("ins");
        if (freeins != null && freeins.value == "No") { myOrderData.insurance = parseFloat(ins.value); myOrderData.freeInsurance = false; }
        else { myOrderData.insurance = 0; myOrderData.freeInsurance = true; }
        myOrderData.total = parseFloat(myOrderData.tax1) + parseFloat(myOrderData.tax2) + parseFloat(myOrderData.subTotal) + parseFloat(myOrderData.insurance ?? 0) + parseFloat(myOrderData.shipping ?? 0);
        var x = document.getElementById("total");
        if (x != null) {
            x.rows[0].cells[1].innerHTML = twoDigitFormatNum(myOrderData.totalCost);
            x.rows[1].cells[1].innerHTML = twoDigitFormatNum(myOrderData.totalProfit);
            x.rows[2].cells[1].innerHTML = twoDigitFormatNum(myOrderData.subTotal);
            x.rows[3].cells[1].innerHTML = twoDigitFormatNum(myOrderData.shipping);
            x.rows[4].cells[1].innerHTML = twoDigitFormatNum(myOrderData.insurance);
            x.rows[5].cells[1].innerHTML = twoDigitFormatNum(myOrderData.tax1);
            x.rows[6].cells[1].innerHTML = twoDigitFormatNum(myOrderData.tax2);
            x.rows[8].cells[1].innerHTML = twoDigitFormatNum(myOrderData.total);
        }
    }

    function handleChange(event) {
        const value = event.target.value;
        setMyOrderData({ ...myOrderData, [event.target.name]: value });
    };

    return (
        <>
            {props.selectedRow != undefined && props.selectedRow != "" &&
                <form className="form-box" onSubmit={saveOrder} key={myOrderData.id}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: "10%" }}>Order No</td>
                                <td style={{ width: "15%" }}><input type="text" value="" readOnly style={{ backgroundColor: 'lightgray', width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; PO Number</td>
                                <td style={{ width: "20%" }}><input name="ponum" type="text" value={myOrderData.ponum} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Revision Change</td>
                                <td style={{ width: "15%" }}><input type="number" value={myOrderData.revision} readOnly style={{ textAlign: 'right', backgroundColor: 'lightgray', width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Cust Code</td>
                                <td style={{ width: "15%" }}> <Autocomplete id="mCustCode" options={custCode} sx={{ width: 180 }} size="small" renderInput={(params) => <TextField {...params} variant="standard" />} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Po Date</td>
                                <td style={{ width: "20%" }}> <input name="orderDate" type="text" value={dateFormatNoT(myOrderData.orderDate)} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Free Ship</td>
                                <td style={{ width: "15%" }}><select id="freeShip" name="freeShip" onChange={calculate}><option value="Yes">Yes</option><option value="No">No</option></select> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Employno</td>
                                <td style={{ width: "15%" }}> <Autocomplete id="mEmpData" options={empData} sx={{ width: 180 }} size="small" renderInput={(params) => <TextField {...params} variant="standard" />} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Po Ship Date</td>
                                <td style={{ width: "20%" }}> <input name="orderShipDate" type="text" value={dateFormatNoT(myOrderData.orderShipDate)} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Shipping Charges</td>
                                <td style={{ width: "15%" }}> <input name="shippingAmt" type="text" value={myOrderData.shippingAmt ?? 0} onChange={handleChange} onBlur={calculate} style={{ textAlign: 'right', width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Currency</td>
                                <td style={{ width: "15%" }}> <input name="currency" type="text" value={myOrderData.currency} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Po Issue By</td>
                                <td style={{ width: "20%" }}> <input name="issueBy" type="text" value={myOrderData.issueBy} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Free Shipping Cost</td>
                                <td style={{ width: "15%" }}> <input name="shipCost" type="text" value={myOrderData.shipCost} onChange={handleChange} style={{ textAlign: 'right', width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Terms</td>
                                <td style={{ width: "15%" }}> <input name="terms" type="text" value={myOrderData.terms} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Order Taken By</td>
                                <td style={{ width: "20%" }}> <input name="orderTakenBy" type="text" value={myOrderData.orderTakenBy} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Free Insurance</td>
                                <td style={{ width: "15%" }}><select id="freeIns" name="freeIns" onChange={calculate}><option value="Yes">Yes</option><option value="No">No</option></select> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> GST/HST</td>
                                <td style={{ width: "15%" }}> <input name="tax1" type="text" value={myOrderData.tax1} onChange={handleChange} onBlur={calculate} style={{ textAlign: 'right', width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Courier By</td>
                                <td style={{ width: "20%" }}> <input name="courierBy" type="text" value={myOrderData.courierBy} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Insurance</td>
                                <td style={{ width: "15%" }}> <input name="insurance" type="text" id="ins" value={myOrderData.insurance ?? 0} onChange={handleChange} onBlur={calculate} style={{ textAlign: 'right', width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> PST</td>
                                <td style={{ width: "15%" }}> <input name="tax2" type="text" value={myOrderData.tax2} onChange={handleChange} onBlur={calculate} style={{ textAlign: 'right', width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Our Ship Date</td>
                                <td style={{ width: "20%" }}> <input name="ourShipDate" type="date" value={myOrderData.ourShipDate} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Term Remark</td>
                                <td style={{ width: "15%" }}> <input name="terms" type="text" value={myOrderData.terms} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: "5%" }}> Shipping</td>
                                <td style={{ width: "40%" }}> <input name="note1" type="text" value={myOrderData.note1} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "15%" }}>&nbsp; Shipping Company</td>
                                <td style={{ width: "30%" }}><input name="shippingCompany" type="text" value={myOrderData.shippingCompany} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "2%" }}> $</td>
                                <td style={{ width: "8%" }}> <input name="shippingAmt" type="text" value={myOrderData.shippingAmt} onChange={handleChange} style={{ textAlign: 'right', width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}> Instructions</td>
                                <td style={{ width: "40%" }}> <input name="note2" type="text" value={myOrderData.note2} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td colSpan="4" style={{ width: "55%" }}>&nbsp; Tracking #</td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}> </td>
                                <td style={{ width: "40%" }}> <input name="note3" type="text" value={myOrderData.note3} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "55%", paddingLeft: "5px" }} colSpan="4" rowSpan="2"><textarea name="trackingNum" rows="2" style={{ width: "100%" }} onChange={handleChange}> </textarea></td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}> </td>
                                <td style={{ width: "40%" }}> <input name="note4" type="text" value={myOrderData.note4} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "55%" }} colSpan="4"> </td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "10%" }}> BillTo</td>
                                <td style={{ width: "40%" }}> <input name="bcompany" type="text" value={myOrderData.bcompany} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; ShipTo</td>
                                <td style={{ width: "40%" }}> <input name="scompany" type="text" value={myOrderData.scompany} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Contact</td>
                                <td style={{ width: "40%" }}> <input name="bcontact" type="text" value={myOrderData.bcontact} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Contact</td>
                                <td style={{ width: "40%" }}> <input name="scontact" type="text" value={myOrderData.scontact} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Address1</td>
                                <td style={{ width: "40%" }}> <input name="baddress1" type="text" value={myOrderData.baddress1} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Address1</td>
                                <td style={{ width: "40%" }}> <input name="saddress1" type="text" value={myOrderData.saddress1} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Address2</td>
                                <td style={{ width: "40%" }}> <input name="baddress2" type="text" value={myOrderData.baddress2} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Address2</td>
                                <td style={{ width: "40%" }}> <input name="saddress2" type="text" value={myOrderData.saddress2} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> City</td>
                                <td style={{ width: "40%" }}> <input name="bcity" type="text" value={myOrderData.bcity} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; City</td>
                                <td style={{ width: "40%" }}> <input name="scity" type="text" value={myOrderData.scity} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Province</td>
                                <td style={{ width: "40%" }}> <input name="bprovince" type="text" value={myOrderData.bprovince} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Province</td>
                                <td style={{ width: "40%" }}> <input name="sprovince" type="text" value={myOrderData.sprovince} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Postal Code</td>
                                <td style={{ width: "40%" }}> <input name="bpostcode" type="text" value={myOrderData.bpostcode} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Postal Code</td>
                                <td style={{ width: "40%" }}> <input name="spostcode" type="text" value={myOrderData.spostcode} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Country</td>
                                <td style={{ width: "40%" }}> <input name="bcountry" type="text" value={myOrderData.bcountry} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Country</td>
                                <td style={{ width: "40%" }}> <input name="scountry" type="text" value={myOrderData.scountry} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Phone</td>
                                <td style={{ width: "40%" }}> <input name="bphone" type="text" value={myOrderData.bphone} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Phone</td>
                                <td style={{ width: "40%" }}> <input name="sphone" type="text" value={myOrderData.sphone} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Fax No</td>
                                <td style={{ width: "40%" }}> <input name="bfax" type="text" value={myOrderData.bfax} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Fax No</td>
                                <td style={{ width: "40%" }}> <input name="sfax" type="text" value={myOrderData.sfax} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                            <tr>
                                <td style={{ width: "10%" }}> Email</td>
                                <td style={{ width: "40%" }}> <input name="bemail" type="text" value={myOrderData.bemail} onChange={handleChange} style={{ width: "100%" }} /> </td>
                                <td style={{ width: "10%" }}>&nbsp; Email</td>
                                <td style={{ width: "40%" }}> <input name="semail" type="text" value={myOrderData.semail} onChange={handleChange} style={{ width: "100%" }} /> </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <ItemCode details={myOrderData} itemCodeToOrderForm={itemCodeToOrderForm} />
                    <br />
                    <br />
                    <input type="submit" value="Submit" style={{ backgroundColor: "lightpink" }} />
                </form>
            }
        </>
    );
}