import React, { useState } from 'react';
import { dateFormat, twoDigitFormat } from '../Shared/Utils';
import DisplayGrid from '../Shared/DisplayGrid';
import CustomerForm from './CustomerForm';

const columns = [
    { field: 'custrefno', headerName: 'custrefno', hide: true, headerClassName: 'steelheader' },
    { field: 'custno', headerName: 'CustCode', width: 100, headerClassName: 'steelheader' },
    { field: 'company', headerName: 'Company', flex: 100, headerClassName: 'steelheader' },
    { field: 'phone', headerName: 'Phone', width: 120, headerClassName: 'steelheader' },
    { field: 'contact', headerName: 'Contact', flex: 70, headerClassName: 'steelheader' },
    { field: 'employno', headerName: 'Sales', width: 70, headerClassName: 'steelheader' }
];

export default function CustomerData() {
        //{JSON.stringify(selectedRow[0])}
    const [selectedRow, setSelectedRow] = useState([]);

    const childToParent = (childdata) => {
        setSelectedRow(childdata);
    }
    return (
        <>
            <DisplayGrid columns={columns} URL={"Customer?hasCustCode="} title={"Customer"} from={""} dateFilter={false}
                id={(r) => r.custrefno} custCodeFilter={false} defaultCustCode={""} cbCustCode={true} cbConverted={false} cbCancel={false}
                selectedRow={childToParent} isUploaded={false} sourceType={""}
                backgroundColor={"steelblue"} alternateColor={"lightblue"} color={"white"} hoverColor={"lightgray"} pageSize={5} />

            {selectedRow.length > 0 &&
                <CustomerForm selectedRow={selectedRow[0]} />
            }
        </>
    );
}