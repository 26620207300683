import React, { useState } from 'react';
import axios from 'axios';

export default function UploadFile (props) {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const uploadFile = async (e) => {
        props.setUploaded(false);

        const formData = new FormData();
        formData.append("formFile", file);
        formData.append("fileName", fileName);
        formData.append("sourceType", props.sourceType)
        try {
            const res = await axios.post("file", formData);
            if (res.status == 200) {
                props.setUploaded(true);
                alert(res.data);
            } else if (res.status == 403) {
                alert("Duplicate Order ID found in the file.");
            } else {
                alert("Failed to upload file.");
            }
        } catch (ex) {
            alert("Failed to upload file. Duplicate ID # " + ex.response.data + " found.");
        }
    };

    return (
        <span style={{ backgroundColor: 'lightgreen', border:'solid 1px', padding: '10px 5px 10px 5px' }}>
            <input type="file" onChange={saveFile} accept=".txt, .csv, .xls, .xlsx" />
            <input type="button" value="upload" onClick={uploadFile} />
        </span>
        )
};

